// 3rd
import {
  Progress as ChakraProgress,
  ProgressLabel as ChakraProgressLabel,
  Skeleton as ChakraSkeleton,
  useStyleConfig,
} from '@chakra-ui/react';
import type {
  ProgressProps as ChakraProgressProps,
  StyleProps,
  ChakraProps,
} from '@chakra-ui/react';

// App - Types
import type { KeyOf } from '@/types/keyof';

// App - Other
import { progressVariants, progressSizes } from '@/config/theme/components/progress';

export type ProgressProps = Pick<ChakraProgressProps, 'hasStripe' | 'min' | 'max' | 'value'> &
  StyleProps &
  ChakraProps & {
    variant?: KeyOf<typeof progressVariants>;
    size?: KeyOf<typeof progressSizes>;
    label?: string;
  };

export const Progress = ({ label, ...props }: ProgressProps) => {
  return (
    <ChakraProgress {...props}>
      {label ? <ChakraProgressLabel>{label}</ChakraProgressLabel> : null}
    </ChakraProgress>
  );
};

type LoadingProps = StyleProps &
  ChakraProps & {
    variant?: KeyOf<typeof progressVariants>;
    size?: KeyOf<typeof progressSizes>;
  };

const Loading = ({ variant, size, ...props }: LoadingProps) => {
  const progressStyles = useStyleConfig('Progress', {
    variant,
    size,
  }) as { track: StyleProps };
  const {
    track: { h, borderRadius },
  } = progressStyles;

  return <ChakraSkeleton {...props} h={h as string} borderRadius={borderRadius as string} />;
};

Progress.Loading = Loading;
