// React & Next
import { useMemo } from 'react';
import type { CSSProperties } from 'react';

// 3rd
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  PieChart as ReChartsPieChart,
  Pie as ReChartsPie,
  Cell as ReChartsCell,
  Legend as ReChartsLegend,
  Tooltip,
  Label,
} from 'recharts';
import type {
  HorizontalAlignmentType,
  Payload as ReChartsLegendPayload,
  VerticalAlignmentType,
} from 'recharts/types/component/DefaultLegendContent';
import type {
  Payload as ReChartsTooltipPayload,
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import type { LayoutType } from 'recharts/types/util/types';

// App - Other
import { CircleIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

type DoubleDoughnutSector = {
  name: string;
  value: number;
  color: string;
};

type DoubleDoughnutProps = {
  innerText?: string;
  outerData: DoubleDoughnutSector[];
  innerData: DoubleDoughnutSector[];
  cy?: number | string;
  legendProps?: {
    layout?: LayoutType;
    align?: HorizontalAlignmentType;
    verticalAlign?: VerticalAlignmentType;
    wrapperStyle?: CSSProperties;
    variant?: 'small' | 'body';
    color?: string;
  };
};

export const DoubleDoughnut = ({
  innerText,
  outerData,
  innerData,
  cy,
  legendProps = {},
}: DoubleDoughnutProps) => {
  const { variant = 'body', color = 'text.high-tone', ...restLegendProps } = legendProps;

  return (
    <ReChartsResponsiveContainer width="100%" height="85%">
      <ReChartsPieChart>
        <ReChartsPie
          data={innerData}
          dataKey="value"
          innerRadius="45%"
          outerRadius="69%"
          cy={cy || '57%'}
        >
          {innerData.map((entry, index) => (
            <ReChartsCell key={`inner-cell-${index}`} fill={entry.color} />
          ))}

          {innerText ? (
            <Label
              value={innerText}
              position="center"
              fontSize="16px"
              fontWeight={500}
              color="#0F172A"
            />
          ) : null}
        </ReChartsPie>

        <ReChartsPie
          data={outerData}
          dataKey="value"
          innerRadius="76%"
          outerRadius="100%"
          cy={cy || '57%'}
          legendType="none"
          tooltipType="none"
        >
          {outerData.map((entry, index) => (
            <ReChartsCell key={`outer-cell-${index}`} fill={entry.color} />
          ))}
        </ReChartsPie>

        <Tooltip
          offset={-50}
          content={(props) => (
            <CustomTooltip label={props.label} payload={props.payload} active={props.active} />
          )}
        />

        <ReChartsLegend
          layout="vertical"
          verticalAlign="bottom"
          align="center"
          content={(props) => (
            <RenderLegend payload={props.payload || []} color={color} variant={variant} />
          )}
          wrapperStyle={{
            left: '0',
            top: '100%',
            minHeight: '60px',
          }}
          {...restLegendProps}
        />
      </ReChartsPieChart>
    </ReChartsResponsiveContainer>
  );
};

type RenderLegendProps = {
  payload: ReChartsLegendPayload[];
  variant: 'small' | 'body';
  color: string;
};

const RenderLegend = ({ payload, variant, color }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex direction="column">
      {payload
        .filter((entry) => entry.type !== 'none')
        .map((entry, index) => (
          <Flex key={`item-${entry.value}-${index}`} alignItems="center" gap="xs">
            <CircleIcon
              w="6px"
              h="6px"
              aria-label="Legend Circle Icon"
              zIndex={1}
              color={entry.color}
            />

            <Text variant={variant} color={color}>
              {entry.payload?.value !== undefined ? `${entry.payload?.value} ` : ''}
              {legendText(entry.value)}
            </Text>
          </Flex>
        ))}
    </Flex>
  );
};

type CustomTooltipProps = {
  payload?: ReChartsTooltipPayload<ValueType, NameType>[];
  label: string;
  active?: boolean;
};

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="sm"
        py="xs"
        px="sm"
        border="1px"
        borderColor="border.primary"
        bg="surface.primary"
        borderRadius="sm"
        boxShadow="elevation-2"
      >
        <Text variant="detail" color="text.secondary">
          {payload[0].name}
        </Text>

        <Text variant="detail" color="text.primary">
          {payload[0].value}
        </Text>
      </Flex>
    );
  }

  return null;
};

type LoadingProps = {
  size: number;
  noOfLegends?: number;
};

const Loading = ({ size, noOfLegends }: LoadingProps) => {
  const legends = useMemo(() => {
    if (noOfLegends === undefined || noOfLegends < 0) return Array.from({ length: 3 });

    return Array.from({ length: noOfLegends });
  }, [noOfLegends]);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      h="100%"
      pb="8px"
    >
      <SkeletonCircle size={`${size}px`} mx="auto" mt="6px" />

      <Flex direction="column" gap="xs" minH="60px">
        {legends.map((_, index) => (
          <Flex key={index} alignItems="center" gap="xs">
            <SkeletonCircle size="6px" />

            <Skeleton height="18px" width="88px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

DoubleDoughnut.Loading = Loading;
